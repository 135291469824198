.group {
  --menuSpanHeight: 7px;
  height: calc(var(--menuSpanHeight) * 3.5);
}
.hamburger {
  @apply inline-block
    ml-1
    cursor-pointer
    relative;
  margin-left: 1rem;
}

.bar {
  @apply flex relative;
  width: calc(var(--menuSpanHeight) * 2.75);
}

.list {
  @apply grid
    grid-cols-3
    gap-4
    items-end;
}

.hamburger span {
  background-color: #00c2c4;
  border-radius: 15px;
  display: block;
  height: calc(var(--menuSpanHeight));
  position: relative;
  right: 0;
  transition: all ease-in-out 300ms;
  width: calc(var(--menuSpanHeight) * 2.75);
}

.hamburger span.first {
  margin-bottom: 2px;
  /* margin-top: 8px; */
  width: calc(var(--menuSpanHeight) * 4);
}

.hamburger span.second {
  margin-bottom: 2px;
}

.hamburger span.secondDot {
  background-color: #ff945b;
  height: var(--menuSpanHeight);
  left: calc(var(--menuSpanHeight) * 2.75 + 1px);
  position: absolute;
  width: var(--menuSpanHeight);
}

.hamburger span.thirdDot {
  background-color: #ff945b;
  height: var(--menuSpanHeight);
  margin-right: 1px;
  width: var(--menuSpanHeight);
}

.group:hover {
  .second {
    transform: translateX(calc(var(--menuSpanHeight) * 1.25));
  }
  .secondDot {
    transform: translateX(calc(var(--menuSpanHeight) * -2.75 - 1px));
  }
  .hamburger span.third {
    margin-left: 1px;
  }
  .third {
    transform: translateX(calc(var(--menuSpanHeight) * -1.25));
  }
  .thirdDot {
    transform: translateX(calc(var(--menuSpanHeight) * 3));
  }
}

.showMenu {
  @apply block;
}

.hideMenu {
  @apply hidden;
}

.menuContainer {
  @apply w-80 absolute top-12 right-3 bg-white border border-divider rounded-lg z-30 p-4;
}

.showMenuOverlay {
  @apply fixed h-screen w-screen top-0 left-0 bg-green bg-opacity-0 cursor-default z-20;
}

.hideMenuOverlay {
  @apply hidden;
}
@media (max-width: 600px) {
  .menuContainer {
    width: 100vw;
    right: 0;
    position: fixed;
    height: calc(100vh - 5rem);
    top: 5rem;
  }
  .list {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    place-items: center;
  }
}
