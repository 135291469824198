.container {
  @apply cursor-pointer
    w-24
    text-dark-gray;
}

.svg {
  @apply mx-auto my-2;
}

.navigation_heading {
  @apply text-footer text-opacity-80 font-gilroy font-light duration-200 text-center;
}

.inner_circle {
  transform: scale(1) translate(0px, 0px);
  transition: all 0.36s ease-out;
}

a[data-active="true"] .icon_green {
  @apply text-green;
}

a[data-active="true"] .icon_orange {
  @apply text-orange;
}

a[data-active="true"] .navigation_heading {
  @apply text-footer-hover font-medium;
}

.container:hover {
  .inner_circle {
    transform: scale(0.8) translate(3px, 5px);
    transition: all 0.36s ease-out;
  }
  .icon_orange {
    @apply text-orange;
  }
  .icon_green {
    @apply text-green;
  }
  .navigation_heading {
    @apply text-footer-hover font-medium;
  }
}
