.title {
  @apply font-bold
    text-lg
    mb-4;
}

.list {
  @apply text-footer;
}

.item {
  @apply text-sm
    cursor-pointer
    mb-1.25
    text-dark-gray;

  &:hover {
    @apply text-green;
  }
}
