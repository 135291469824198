.box {
  @apply border-t
    border-gray-200
    pt-8
    pb-16
    px-4
    lg:mx-16
    lg:px-0;
}

.list {
  @apply grid
    grid-cols-1
    gap-4
    place-items-center
    text-center
    sm:grid-cols-2
    lg:grid-cols-5
    lg:place-items-start
    lg:text-left;
}
