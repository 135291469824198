.button {
  cursor: pointer;
}

.initials {
  display: block;
  position: relative;
  text-align: center;
  right: 0px;
  left: 0;
  font-size: 12px;
  top: 3px;
}
