.container {
  @apply relative;
}

.modalContainer {
  /* Tailwind limitation */
  transform: translate(-50%, -50%);
  @apply fixed z-50 bg-white top-1/2 left-1/2 p-8 rounded-xl max-w-md w-full;
}

.modalSubcontainer {
  @apply relative;
}

.close {
  @apply absolute -top-2 -right-2 text-5xl transform rotate-45 leading-6 p-2 text-orange cursor-pointer rounded-full;

  &:hover {
    @apply bg-green duration-150 text-white;
  }

  &:focus {
    @apply outline-none;
  }
  top: 0.5rem;
  right: 0.5rem;
}
.modalOverlay {
  @apply h-screen w-screen bg-black bg-opacity-20 fixed top-0 left-0 z-40;
}
