.container {
  @apply relative;
}
.userIcon {
  height: auto;
  width: 25px;
  min-width: 25px;
}

.modalOverlay {
  @apply h-screen w-screen bg-black bg-opacity-20 fixed top-0 left-0 z-40;
}

.modalContainer {
  /* Tailwind limitation */
  transform: translate(-50%, -50%);
  @apply fixed z-50 bg-white top-1/2 left-1/2 p-8 rounded-xl max-w-md w-full;
}

.modalSubcontainer {
  @apply relative;
}

.close {
  @apply absolute -top-2 -right-2 text-5xl transform rotate-45 leading-6 p-2 text-orange cursor-pointer rounded-full;

  &:hover {
    @apply bg-green duration-150 text-white;
  }

  &:focus {
    @apply outline-none;
  }
}

.button {
  @apply grid
    place-content-center;
}

.tooltip {
  @apply grid grid-cols-2 border-gray-200 py-3 bg-white;
}

.tooltipRegister {
  @apply text-footer hover:text-footer-hover duration-150 cursor-pointer py-1 my-1 px-8 border-r border-light-gray text-base font-gilroy font-extralight;
}

.tooltipLogin {
  @apply text-footer hover:text-footer-hover duration-150 cursor-pointer py-1 my-1 px-8 text-base font-gilroy font-extralight;
}

.tooltipContainer {
  @apply w-full h-12 bg-transparent;
}

.modalHeading {
  @apply text-xl font-medium mt-2 mb-6 pr-8;
}

.modalSubheading {
  @apply text-orange font-semibold mb-3 mt-10;
}

.modalInput {
  @apply focus:outline-none border border-orange rounded-md w-full py-4 px-4 my-3;
  border-color: #959595;
}
.modalInput:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}
.modalInput:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
@keyframes onAutoFillStart {
}
@keyframes onAutoFillCancel {
}

.modalForgotten {
  @apply text-right font-medium underline hover:no-underline hover:text-footer-hover cursor-pointer;
}

.modalParagraph {
  @apply h-24 mt-4 leading-5;
}

.modalParagraphSpan {
  @apply text-green hover:font-medium cursor-pointer;
}

.modalLoginContainer {
  @apply max-w-sm mx-auto my-4 px-4;
}

.modalLogin {
  @apply w-full bg-green hover:bg-orange duration-150 text-white text-lg rounded-xl py-5;

  &:focus {
    @apply outline-none;
  }
}

.modalRegister {
  @apply text-center font-light my-3;
}

.modalRegisterSpan {
  @apply font-bold underline hover:no-underline hover:text-footer-hover cursor-pointer;
}
.errorLabel {
  font-size: 0.75rem;
  font-weight: 500;
  color: #ff945b;
  position: relative;
  top: -0.5rem;
  left: 0.3rem;
}
.formError {
  font-size: 1rem;
  font-weight: 500;
  color: #ff945b;
  position: relative;
  top: -15px;
}
.inputWrapper {
  position: relative;
}
.inputBad {
  border-color: #ff945b;
}
.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.info {
  color: #00c2c4;
}
@media (max-width: 1000px) {
  .modalContainer {
    top: 0;
    left: 0;
    transform: none;
    height: 100vh;
    overflow-y: scroll;
  }
}
