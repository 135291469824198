.container {
  @apply cursor-pointer
    w-24
    text-dark-gray;
}

.svg {
  @apply mx-auto mt-2 mb-1;
}

.navigation_heading {
  @apply text-footer text-opacity-80 font-gilroy font-light duration-200 text-center;
}

.image,
.heading_1,
.heading_2 {
  transform: translate(0px, 0px);
  transition: all 0.3s ease-out;
}

a[data-active="true"] .icon_green {
  @apply text-green;
}

a[data-active="true"] .icon_orange {
  @apply text-orange;
}

a[data-active="true"] .navigation_heading {
  @apply text-footer-hover font-medium;
}

.container:hover {
  .icon_orange {
    @apply text-orange;
  }
  .icon_green {
    @apply text-green;
  }
  .navigation_heading {
    @apply text-footer-hover font-medium;
  }
  .image {
    transform: translate(9px, -5px);
    transition: all 0.3s ease-out;
  }
  .heading_1 {
    @apply opacity-0;
    transform: translate(6px, 4px);
    transition: all 0.3s ease-out;
  }
  .heading_2 {
    transform: translate(-7px, 4px);
    transition: all 0.3s ease-out;
  }
}
