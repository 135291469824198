.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: 15px;
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
  cursor: pointer;
  div {
    border-radius: 50px;
    height: 2px;
    width: 100%;
    background: #5d5d5d;
  }
}
@media (max-width: 700px) {
  .hamburger {
    display: flex;
  }
}
.menu {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
  overflow-y: scroll;
  flex-direction: column;
  padding: 2rem 1rem;
  a {
    margin-left: 2rem;
  }
  h4 {
    margin: 1rem;
  }
  .logo {
    margin: 0;
  }
  &.active {
    display: flex;
  }
  .navs {
    margin-top: 1rem;
  }
  .cross {
    position: absolute;
    right: 1rem;
    top: 2.2rem;
    color: rgb(255, 148, 91);
    transform: rotate(45deg);
    font-size: 3rem;
    height: 20px;
    width: 20px;
    line-height: 20px;
    cursor: pointer;
  }
  .footer {
    .line {
      width: 100%;
      height: 1px;
      background-color: #ebebeb;
      margin: 1rem 0;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      h3 {
        cursor: pointer;
      }
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
          margin-left: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }
}
