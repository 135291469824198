.container {
  @apply flex;
  height: 2.5rem;
  margin: auto 0;
}

.subContainer {
  @apply flex
    ml-auto;
  align-items: flex-end;
}

.menu {
  @apply flex
    items-center;
  align-items: flex-end;
  margin-left: 10px;
}
