.inputWrapper {
  position: relative;
}
.modalInput {
  @apply focus:outline-none border border-orange rounded-md w-full py-4 px-4 my-3;
  border-color: #959595;
}
.modalInput:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}
.modalInput:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
@keyframes onAutoFillStart {
}
@keyframes onAutoFillCancel {
}

.errorLabel {
  font-size: 0.75rem;
  font-weight: 500;
  color: #ff945b;
  position: relative;
  top: -0.5rem;
  left: 0.3rem;
}

.inputBad {
  border-color: #ff945b;
}
