.leftMenu_hamburger__GtVUs {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 15px;
  width: 20px;
  min-width: 20px;
  margin-right: 10px;
  cursor: pointer;
}
  .leftMenu_hamburger__GtVUs div {
    border-radius: 50px;
    height: 2px;
    width: 100%;
    background: #5d5d5d;
  }
@media (max-width: 700px) {
  .leftMenu_hamburger__GtVUs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.leftMenu_menu__cz1ST {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background: white;
  overflow-y: scroll;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 2rem 1rem;
}
.leftMenu_menu__cz1ST a {
    margin-left: 2rem;
  }
.leftMenu_menu__cz1ST h4 {
    margin: 1rem;
  }
.leftMenu_menu__cz1ST .leftMenu_logo___CfMP {
    margin: 0;
  }
.leftMenu_menu__cz1ST.leftMenu_active__LxZuX {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
.leftMenu_menu__cz1ST .leftMenu_navs__Moxhm {
    margin-top: 1rem;
  }
.leftMenu_menu__cz1ST .leftMenu_cross__XHrsE {
    position: absolute;
    right: 1rem;
    top: 2.2rem;
    color: rgb(255, 148, 91);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    font-size: 3rem;
    height: 20px;
    width: 20px;
    line-height: 20px;
    cursor: pointer;
  }
.leftMenu_menu__cz1ST .leftMenu_footer__zHGPu .leftMenu_line__1U7O_ {
      width: 100%;
      height: 1px;
      background-color: #ebebeb;
      margin: 1rem 0;
    }
.leftMenu_menu__cz1ST .leftMenu_footer__zHGPu .leftMenu_container__23chr {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 0 1rem;
    }
.leftMenu_menu__cz1ST .leftMenu_footer__zHGPu .leftMenu_container__23chr h3 {
        cursor: pointer;
      }
.leftMenu_menu__cz1ST .leftMenu_footer__zHGPu .leftMenu_container__23chr div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }
.leftMenu_menu__cz1ST .leftMenu_footer__zHGPu .leftMenu_container__23chr div svg {
          margin-left: 0.5rem;
          cursor: pointer;
        }

.generic_title__qD0s9 {

    margin-bottom: 1rem;

    font-size: 1.125rem;

    line-height: 1.75rem;

    font-weight: 700;
}

.generic_list__VL3Ys {

    --tw-text-opacity: 1;

    color: rgba(46, 49, 56, 1);

    color: rgba(46, 49, 56, var(--tw-text-opacity, 1));
}

.generic_item__pgWO_ {

    margin-bottom: 0.3125rem;

    cursor: pointer;

    font-size: 0.875rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgba(149, 149, 149, 1);

    color: rgba(149, 149, 149, var(--tw-text-opacity, 1));
}

.generic_item__pgWO_:hover {

    --tw-text-opacity: 1;

    color: rgba(0, 194, 196, 1);

    color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.column_column__LCxVH {
    grid-column: span 1 / span 1
}

.novostaveb_container__1L51i {
  width: 6rem;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, 1);
  color: rgba(149, 149, 149, var(--tw-text-opacity, 1));
}

.novostaveb_svg__ZJAvf {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.novostaveb_navigation_heading__r9Qyr {
  text-align: center;
  font-family: Gilroy;
  font-weight: 300;
  color: rgba(46, 49, 56, 0.8);
  color: rgba(46, 49, 56, var(--tw-text-opacity, 1));
  --tw-text-opacity: 0.8;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

.novostaveb_inner_circle__9NRju {
  -webkit-transform: scale(1) translate(0px, 0px);
          transform: scale(1) translate(0px, 0px);
  -webkit-transition: all 0.36s ease-out;
  transition: all 0.36s ease-out;
}

a[data-active="true"] .novostaveb_icon_green__PMibf {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

a[data-active="true"] .novostaveb_icon_orange__i4JNr {
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}

a[data-active="true"] .novostaveb_navigation_heading__r9Qyr {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}

.novostaveb_container__1L51i:hover .novostaveb_inner_circle__9NRju {
    -webkit-transform: scale(0.8) translate(3px, 5px);
            transform: scale(0.8) translate(3px, 5px);
    -webkit-transition: all 0.36s ease-out;
    transition: all 0.36s ease-out;
  }

.novostaveb_container__1L51i:hover .novostaveb_icon_orange__i4JNr {
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}

.novostaveb_container__1L51i:hover .novostaveb_icon_green__PMibf {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.novostaveb_container__1L51i:hover .novostaveb_navigation_heading__r9Qyr {
  font-weight: 500;
}

.novostaveb_container__1L51i:hover .novostaveb_navigation_heading__r9Qyr {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}

.studio_container__VxeEt {
  width: 6rem;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(149, 149, 149, 1);
  color: rgba(149, 149, 149, var(--tw-text-opacity, 1));
}

.studio_svg__1ModE {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}

.studio_navigation_heading__rnsyn {
  text-align: center;
  font-family: Gilroy;
  font-weight: 300;
  color: rgba(46, 49, 56, 0.8);
  color: rgba(46, 49, 56, var(--tw-text-opacity, 1));
  --tw-text-opacity: 0.8;
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

.studio_image__8NHu0,
.studio_heading_1__Kim9g,
.studio_heading_2__fP75l {
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

a[data-active="true"] .studio_icon_green__e8ycQ {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

a[data-active="true"] .studio_icon_orange__1hhxt {
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}

a[data-active="true"] .studio_navigation_heading__rnsyn {
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}

.studio_container__VxeEt:hover .studio_icon_orange__1hhxt {
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}

.studio_container__VxeEt:hover .studio_icon_green__e8ycQ {
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.studio_container__VxeEt:hover .studio_navigation_heading__rnsyn {
  font-weight: 500;
}

.studio_container__VxeEt:hover .studio_navigation_heading__rnsyn {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}

.studio_container__VxeEt:hover .studio_image__8NHu0 {
    -webkit-transform: translate(9px, -5px);
            transform: translate(9px, -5px);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

.studio_container__VxeEt:hover .studio_heading_1__Kim9g {
  opacity: 0;
}

.studio_container__VxeEt:hover .studio_heading_1__Kim9g {
    -webkit-transform: translate(6px, 4px);
            transform: translate(6px, 4px);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

.studio_container__VxeEt:hover .studio_heading_2__fP75l {
    -webkit-transform: translate(-7px, 4px);
            transform: translate(-7px, 4px);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

.menu_group__RcJIX {
  --menuSpanHeight: 7px;
  height: calc(7px * 3.5);
  height: calc(var(--menuSpanHeight) * 3.5);
}
.menu_hamburger__zjjLt {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 1rem;
}
.menu_bar__Bilfw {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(var(--menuSpanHeight) * 2.75);
}
.menu_list__f84_S {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
}
.menu_hamburger__zjjLt span {
  background-color: #00c2c4;
  border-radius: 15px;
  display: block;
  height: calc(var(--menuSpanHeight));
  position: relative;
  right: 0;
  -webkit-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  width: calc(var(--menuSpanHeight) * 2.75);
}
.menu_hamburger__zjjLt span.menu_first__HsXf_ {
  margin-bottom: 2px;
  /* margin-top: 8px; */
  width: calc(var(--menuSpanHeight) * 4);
}
.menu_hamburger__zjjLt span.menu_second__5CVWp {
  margin-bottom: 2px;
}
.menu_hamburger__zjjLt span.menu_secondDot__VtiPg {
  background-color: #ff945b;
  height: var(--menuSpanHeight);
  left: calc(var(--menuSpanHeight) * 2.75 + 1px);
  position: absolute;
  width: var(--menuSpanHeight);
}
.menu_hamburger__zjjLt span.menu_thirdDot__Co_Sc {
  background-color: #ff945b;
  height: var(--menuSpanHeight);
  margin-right: 1px;
  width: var(--menuSpanHeight);
}
.menu_group__RcJIX:hover .menu_second__5CVWp {
    -webkit-transform: translateX(calc(var(--menuSpanHeight) * 1.25));
            transform: translateX(calc(var(--menuSpanHeight) * 1.25));
  }
.menu_group__RcJIX:hover .menu_secondDot__VtiPg {
    -webkit-transform: translateX(calc(var(--menuSpanHeight) * -2.75 - 1px));
            transform: translateX(calc(var(--menuSpanHeight) * -2.75 - 1px));
  }
.menu_group__RcJIX:hover .menu_hamburger__zjjLt span.menu_third__0zlR2 {
    margin-left: 1px;
  }
.menu_group__RcJIX:hover .menu_third__0zlR2 {
    -webkit-transform: translateX(calc(var(--menuSpanHeight) * -1.25));
            transform: translateX(calc(var(--menuSpanHeight) * -1.25));
  }
.menu_group__RcJIX:hover .menu_thirdDot__Co_Sc {
    -webkit-transform: translateX(calc(var(--menuSpanHeight) * 3));
            transform: translateX(calc(var(--menuSpanHeight) * 3));
  }
.menu_showMenu__4m0yl {
  display: block;
}
.menu_hideMenu__bHqIK {
  display: none;
}
.menu_menuContainer__lEA1v {
  position: absolute;
  top: 3rem;
  right: 0.75rem;
  z-index: 30;
  width: 20rem;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(235, 235, 235, 1);
  border-color: rgba(235, 235, 235, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding: 1rem;
}
.menu_showMenuOverlay__sY5qm {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 20;
  height: 100vh;
  width: 100vw;
  cursor: default;
  background-color: rgba(0, 194, 196, 0);
  background-color: rgba(0, 194, 196, var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0;
}
.menu_hideMenuOverlay__9LYiT {
  display: none;
}
@media (max-width: 600px) {
  .menu_menuContainer__lEA1v {
    width: 100vw;
    right: 0;
    position: fixed;
    height: calc(100vh - 5rem);
    top: 5rem;
  }
  .menu_list__f84_S {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    justify-items: center;
    place-items: center;
  }
}

.login_container__ICs_r {
  position: relative;
}
.login_userIcon__K69H1 {
  height: auto;
  width: 25px;
  min-width: 25px;
}
.login_modalOverlay__xynUK {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 40;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.2;
}
.login_modalContainer__s5ozW {
  /* Tailwind limitation */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 50;
  width: 100%;
  max-width: 28rem;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding: 2rem;
}
.login_modalSubcontainer__9C0Zf {
  position: relative;
}
.login_close__4qXfN {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  border-radius: 9999px;
  padding: 0.5rem;
  font-size: 3rem;
  line-height: 1;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}
.login_close__4qXfN:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 194, 196, 1);
  background-color: rgba(0, 194, 196, var(--tw-bg-opacity, 1));
}
.login_close__4qXfN:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, 1);
  color: rgba(253, 253, 253, var(--tw-text-opacity, 1));
}
.login_close__4qXfN:hover {
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.login_close__4qXfN:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.login_button__ovmC1 {
  display: grid;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  place-content: center;
}
.login_tooltip__cIZdN {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, 1);
  border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.login_tooltipRegister__b0zBe {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(196, 196, 196, 1);
  border-color: rgba(196, 196, 196, var(--tw-border-opacity, 1));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 200;
  --tw-text-opacity: 1;
  color: rgba(46, 49, 56, 1);
  color: rgba(46, 49, 56, var(--tw-text-opacity, 1));
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.login_tooltipRegister__b0zBe:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}
.login_tooltipLogin__ZT5KT {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 200;
  --tw-text-opacity: 1;
  color: rgba(46, 49, 56, 1);
  color: rgba(46, 49, 56, var(--tw-text-opacity, 1));
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.login_tooltipLogin__ZT5KT:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}
.login_tooltipContainer__GPyaQ {
  height: 3rem;
  width: 100%;
  background-color: transparent;
}
.login_modalHeading__tTBRV {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  padding-right: 2rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
}
.login_modalSubheading__Z3jLt {
  margin-bottom: 0.75rem;
  margin-top: 2.5rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}
.login_modalInput__LihtY {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(255, 148, 91, 1);
  border-color: rgba(255, 148, 91, var(--tw-border-opacity, 1));
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.login_modalInput__LihtY:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.login_modalInput__LihtY {
  border-color: #959595;
}
.login_modalInput__LihtY:-webkit-autofill {
  -webkit-animation-name: login_onAutoFillStart__GvIec;
          animation-name: login_onAutoFillStart__GvIec;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s;
}
.login_modalInput__LihtY:not(:-webkit-autofill) {
  -webkit-animation-name: login_onAutoFillCancel__K2uRB;
          animation-name: login_onAutoFillCancel__K2uRB;
}
@-webkit-keyframes login_onAutoFillStart__GvIec {
}
@keyframes login_onAutoFillStart__GvIec {
}
@-webkit-keyframes login_onAutoFillCancel__K2uRB {
}
@keyframes login_onAutoFillCancel__K2uRB {
}
.login_modalForgotten__jrUtq {
  cursor: pointer;
  text-align: right;
  font-weight: 500;
  text-decoration-line: underline;
}
.login_modalForgotten__jrUtq:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
  text-decoration-line: none;
}
.login_modalParagraph__C8L8V {
  margin-top: 1rem;
  height: 6rem;
  line-height: 1.25rem;
}
.login_modalParagraphSpan__zMJzb {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}
.login_modalParagraphSpan__zMJzb:hover {
  font-weight: 500;
}
.login_modalLoginContainer__vyjS_ {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 24rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.login_modalLogin__MlfRA {
  width: 100%;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 194, 196, 1);
  background-color: rgba(0, 194, 196, var(--tw-bg-opacity, 1));
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, 1);
  color: rgba(253, 253, 253, var(--tw-text-opacity, 1));
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.login_modalLogin__MlfRA:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 148, 91, 1);
  background-color: rgba(255, 148, 91, var(--tw-bg-opacity, 1));
}
.login_modalLogin__MlfRA:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.login_modalRegister__JG3oB {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
  font-weight: 300;
}
.login_modalRegisterSpan__SmN67 {
  cursor: pointer;
  font-weight: 700;
  text-decoration-line: underline;
}
.login_modalRegisterSpan__SmN67:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
  text-decoration-line: none;
}
.login_errorLabel__GSA8Q {
  font-size: 0.75rem;
  font-weight: 500;
  color: #ff945b;
  position: relative;
  top: -0.5rem;
  left: 0.3rem;
}
.login_formError__8QzNV {
  font-size: 1rem;
  font-weight: 500;
  color: #ff945b;
  position: relative;
  top: -15px;
}
.login_inputWrapper__o1Lr_ {
  position: relative;
}
.login_inputBad__jayr7 {
  border-color: #ff945b;
}
.login_disabled__Yw4ry {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.login_info__AD0cT {
  color: #00c2c4;
}
@media (max-width: 1000px) {
  .login_modalContainer__s5ozW {
    top: 0;
    left: 0;
    -webkit-transform: none;
            transform: none;
    height: 100vh;
    overflow-y: scroll;
  }
}

.message_container__QmbBD {
  position: relative;
}

.message_modalContainer__Scy0a {
  /* Tailwind limitation */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 50;
  width: 100%;
  max-width: 28rem;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding: 2rem;
}

.message_modalSubcontainer__439_v {
  position: relative;
}

.message_close__HpLmH {
  position: absolute;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  border-radius: 9999px;
  padding: 0.5rem;
  font-size: 3rem;
  line-height: 1;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}

.message_close__HpLmH:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 194, 196, 1);
  background-color: rgba(0, 194, 196, var(--tw-bg-opacity, 1));
}

.message_close__HpLmH:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, 1);
  color: rgba(253, 253, 253, var(--tw-text-opacity, 1));
}

.message_close__HpLmH:hover {
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.message_close__HpLmH:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.message_close__HpLmH {
  top: 0.5rem;
  right: 0.5rem;
}

.message_modalOverlay__zuIJO {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 40;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.2;
}

.registersuccess_modalHeading__H0Pmz {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
}
.registersuccess_info__URP3c {
  color: #00c2c4;
}

.loginform_container__Lf_ox {
  position: relative;
}
.loginform_userIcon__taEDX {
  height: auto;
  width: 25px;
  min-width: 25px;
}
.loginform_modalOverlay__7_Hni {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 40;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
  --tw-bg-opacity: 0.2;
}
.loginform_modalContainer__3tdWm {
  /* Tailwind limitation */
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 50;
  width: 100%;
  max-width: 28rem;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding: 2rem;
}
.loginform_modalSubcontainer__lPqg_ {
  position: relative;
}
.loginform_close__d40eI {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(45deg) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  cursor: pointer;
  border-radius: 9999px;
  padding: 0.5rem;
  font-size: 3rem;
  line-height: 1;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}
.loginform_close__d40eI:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 194, 196, 1);
  background-color: rgba(0, 194, 196, var(--tw-bg-opacity, 1));
}
.loginform_close__d40eI:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, 1);
  color: rgba(253, 253, 253, var(--tw-text-opacity, 1));
}
.loginform_close__d40eI:hover {
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.loginform_close__d40eI:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.loginform_button__8il3k {
  display: grid;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  place-content: center;
}
.loginform_tooltip__XBK96 {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, 1);
  border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
  --tw-bg-opacity: 1;
  background-color: rgba(253, 253, 253, 1);
  background-color: rgba(253, 253, 253, var(--tw-bg-opacity, 1));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.loginform_tooltipRegister__9lZ7R {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  border-right-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(196, 196, 196, 1);
  border-color: rgba(196, 196, 196, var(--tw-border-opacity, 1));
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 200;
  --tw-text-opacity: 1;
  color: rgba(46, 49, 56, 1);
  color: rgba(46, 49, 56, var(--tw-text-opacity, 1));
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.loginform_tooltipRegister__9lZ7R:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}
.loginform_tooltipLogin__8zBkY {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: Gilroy;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 200;
  --tw-text-opacity: 1;
  color: rgba(46, 49, 56, 1);
  color: rgba(46, 49, 56, var(--tw-text-opacity, 1));
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.loginform_tooltipLogin__8zBkY:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
}
.loginform_tooltipContainer__fRId5 {
  height: 3rem;
  width: 100%;
  background-color: transparent;
}
.loginform_modalHeading__YRk_i {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
}
.loginform_modalSubheading__WrDbr {
  margin-bottom: 0.75rem;
  margin-top: 2.5rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(255, 148, 91, 1);
  color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}
.loginform_modalForgotten__Ao8pY {
  cursor: pointer;
  text-align: right;
  font-weight: 500;
  text-decoration-line: underline;
}
.loginform_modalForgotten__Ao8pY:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
  text-decoration-line: none;
}
.loginform_modalParagraph__pmFmP {
  margin-top: 1rem;
  height: 6rem;
  line-height: 1.25rem;
}
.loginform_modalParagraphSpan__WSSZS {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(0, 194, 196, 1);
  color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}
.loginform_modalParagraphSpan__WSSZS:hover {
  font-weight: 500;
}
.loginform_modalLoginContainer__V0ECn {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 24rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.loginform_modalLogin__TDwIx {
  width: 100%;
  border-radius: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 194, 196, 1);
  background-color: rgba(0, 194, 196, var(--tw-bg-opacity, 1));
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(253, 253, 253, 1);
  color: rgba(253, 253, 253, var(--tw-text-opacity, 1));
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}
.loginform_modalLogin__TDwIx:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 148, 91, 1);
  background-color: rgba(255, 148, 91, var(--tw-bg-opacity, 1));
}
.loginform_modalLogin__TDwIx {
  text-align: center;
  cursor: pointer;
}
.loginform_modalLogin__TDwIx:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.loginform_modalRegister__FbZtR {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  text-align: center;
  font-weight: 300;
}
.loginform_modalRegisterSpan__cziic {
  cursor: pointer;
  font-weight: 700;
  text-decoration-line: underline;
}
.loginform_modalRegisterSpan__cziic:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 122, 183, 1);
  color: rgba(51, 122, 183, var(--tw-text-opacity, 1));
  text-decoration-line: none;
}
.loginform_errorLabel__xTiWL {
  font-size: 0.75rem;
  font-weight: 500;
  color: #ff945b;
  position: relative;
  top: -0.5rem;
  left: 0.3rem;
}
.loginform_inputBad__JuN6d {
  border-color: #ff945b;
}
.loginform_disabled__B4QJL {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.loginform_info__Wckvc {
  color: #00c2c4;
}

.logininput_inputWrapper__PjDGQ {
  position: relative;
}
.logininput_modalInput__cRyXf {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  width: 100%;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(255, 148, 91, 1);
  border-color: rgba(255, 148, 91, var(--tw-border-opacity, 1));
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.logininput_modalInput__cRyXf:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.logininput_modalInput__cRyXf {
  border-color: #959595;
}
.logininput_modalInput__cRyXf:-webkit-autofill {
  -webkit-animation-name: logininput_onAutoFillStart__cJAZm;
          animation-name: logininput_onAutoFillStart__cJAZm;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s;
}
.logininput_modalInput__cRyXf:not(:-webkit-autofill) {
  -webkit-animation-name: logininput_onAutoFillCancel__phsDG;
          animation-name: logininput_onAutoFillCancel__phsDG;
}
@-webkit-keyframes logininput_onAutoFillStart__cJAZm {
}
@keyframes logininput_onAutoFillStart__cJAZm {
}
@-webkit-keyframes logininput_onAutoFillCancel__phsDG {
}
@keyframes logininput_onAutoFillCancel__phsDG {
}
.logininput_errorLabel__khz2R {
  font-size: 0.75rem;
  font-weight: 500;
  color: #ff945b;
  position: relative;
  top: -0.5rem;
  left: 0.3rem;
}
.logininput_inputBad__c6zhF {
  border-color: #ff945b;
}

.inputeye_inputEye__LXW8j {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.formtoggler_modalRegister___UH6e {

    margin-top: 0.75rem;

    margin-bottom: 0.75rem;

    text-align: center;

    font-weight: 300
}

.formtoggler_modalRegisterSpan__fvhhe {

    cursor: pointer;

    font-weight: 700;

    text-decoration-line: underline
}

.formtoggler_modalRegisterSpan__fvhhe:hover {

    --tw-text-opacity: 1;

    color: rgba(51, 122, 183, 1);

    color: rgba(51, 122, 183, var(--tw-text-opacity, 1));

    text-decoration-line: none
}

.page_main__Riaf4 {
    position: relative;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 4rem
}
@media (min-width: 1024px) {
    .page_main__Riaf4 {
        margin-left: 4rem;
        margin-right: 4rem
    }
}

.headbutton_button__wls0G {
  cursor: pointer;
}

.headbutton_initials__sgquK {
  display: block;
  position: relative;
  text-align: center;
  right: 0px;
  left: 0;
  font-size: 12px;
  top: 3px;
}

.footer_box___yCAB {

    border-top-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgba(229, 231, 235, 1);

    border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));

    padding-left: 1rem;

    padding-right: 1rem;

    padding-top: 2rem;

    padding-bottom: 4rem
}

@media (min-width: 1024px) {

    .footer_box___yCAB {

        margin-left: 4rem;

        margin-right: 4rem;

        padding-left: 0px;

        padding-right: 0px
    }
}

.footer_list__ATke4 {

    display: grid;

    grid-template-columns: repeat(1, minmax(0, 1fr));

    -webkit-box-align: center;

        -ms-flex-align: center;

            align-items: center;

    justify-items: center;

    place-items: center;

    grid-gap: 1rem;

    gap: 1rem;

    text-align: center
}

@media (min-width: 640px) {

    .footer_list__ATke4 {

        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}

@media (min-width: 1024px) {

    .footer_list__ATke4 {

        grid-template-columns: repeat(5, minmax(0, 1fr));

        -webkit-box-align: start;

            -ms-flex-align: start;

                align-items: start;

        justify-items: start;

        place-items: start;

        text-align: left
    }
}

.user_container__SD7cG {
    cursor: pointer;
}
  .user_container__SD7cG svg {
    height: auto;
    width: 23px;
    min-width: 23px;
  }

.info_logo__OVULA {margin-top: 0.5rem;font-size: 0.75rem;line-height: 1rem;
}@media (min-width: 1024px) {.info_logo__OVULA {padding-left: 1rem;padding-right: 1rem;
    }
}

.info_logoText__57rVS {margin-bottom: 0.5rem;cursor: pointer;font-weight: 800;
}

.info_logoText__57rVS:hover .info_logoOrange__lYDZa {--tw-text-opacity: 1;color: rgba(255, 148, 91, 1);color: rgba(255, 148, 91, var(--tw-text-opacity, 1));
}

.info_logoText__57rVS:hover .info_logoOrange__lYDZa {-webkit-transition-duration: 150ms;transition-duration: 150ms;
}

.info_logoText__57rVS:hover .info_logoGreen__IFs8V {--tw-text-opacity: 1;color: rgba(0, 194, 196, 1);color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.info_logoText__57rVS:hover .info_logoGreen__IFs8V {-webkit-transition-duration: 150ms;transition-duration: 150ms;
}

.info_address__bH2lC {margin-top: 0.25rem;margin-bottom: 0.25rem;
}

.info_contact__ZlpHg {margin-top: 0.25rem;margin-bottom: 0.25rem;--tw-text-opacity: 1;color: rgba(0, 0, 0, 1);color: rgba(0, 0, 0, var(--tw-text-opacity, 1));
}

.info_contact__ZlpHg:hover {--tw-text-opacity: 1;color: rgba(0, 194, 196, 1);color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.info_contact__ZlpHg:hover {-webkit-transition-duration: 150ms;transition-duration: 150ms;
}

.info_info__JUlKM {margin-top: 0.25rem;margin-bottom: 0.25rem;
}

.info_socials__MmafJ {margin-top: 1rem;display: -webkit-box;display: -ms-flexbox;display: flex;-ms-flex-line-pack: center;align-content: center;-webkit-box-pack: center;-ms-flex-pack: center;justify-content: center;place-content: center;
}

@media (min-width: 768px) {.info_socials__MmafJ {-ms-flex-line-pack: start;align-content: start;-webkit-box-pack: start;-ms-flex-pack: start;justify-content: start;place-content: start;
    }
}

.info_social__ov4Fg {margin-right: 0.5rem;--tw-text-opacity: 1;color: rgba(0, 0, 0, 1);color: rgba(0, 0, 0, var(--tw-text-opacity, 1));
}

.info_social__ov4Fg:hover {--tw-text-opacity: 1;color: rgba(0, 194, 196, 1);color: rgba(0, 194, 196, var(--tw-text-opacity, 1));
}

.navigation_container__cbdtR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2.5rem;
  margin: auto 0
}

.navigation_subContainer__WHSvK {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end
}

.navigation_menu__dXT8W {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-left: 10px
}

.navigation_container__C2Pec {
  position: relative;
  display: grid;
  height: 5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  place-content: center;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, 1);
  border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
  padding-left: 1rem;
  padding-right: 1rem;
}@media (min-width: 1024px) {.navigation_container__C2Pec {
    margin-left: 4rem;
    margin-right: 4rem;
    padding-left: 0px;
    padding-right: 0px;
  }
}.navigation_container__C2Pec {
  z-index: 21;
}
@media (max-width: 1000px) {
  .navigation_container__C2Pec {
    z-index: 10;
  }
  .navigation_container__C2Pec[data-loginform="true"] {
    z-index: 20;
  }
}
@media (max-width: 1200px) {
  .navigation_container__C2Pec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

