.logo {
  @apply text-xs
    mt-2;

  @apply lg:px-4;
}

.logoText {
  @apply cursor-pointer
    font-extrabold
    mb-2;

  &:hover {
    .logoOrange {
      @apply text-orange
        duration-150;
    }

    .logoGreen {
      @apply text-green
        duration-150;
    }
  }
}

.address {
  @apply my-1;
}

.contact {
  @apply text-black
    my-1;

  &:hover {
    @apply text-green
      duration-150;
  }
}

.info {
  @apply my-1;
}

.socials {
  @apply flex
    place-content-center
    mt-4
    md:place-content-start;
}

.social {
  @apply text-black
    mr-2;

  &:hover {
    @apply text-green;
  }
}
