.container {
  @apply px-4
    border-b
    border-gray-200
    relative
    grid
    grid-cols-3
    place-content-center
    h-20
    lg:px-0
    lg:mx-16;
  z-index: 21;
}
@media (max-width: 1000px) {
  .container {
    @apply z-10;
  }
  .container[data-loginform="true"] {
    @apply z-20;
  }
}
@media (max-width: 1200px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
